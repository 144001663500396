@import "variables";

.button-group{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-group-el{
  margin: 3px;
}