html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

.page-header {
  margin-bottom: 2rem;
}
