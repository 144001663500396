@use 'themes/theme' as theme;
@import 'variables';

.table-container {
  overflow: hidden;
  box-shadow: $mat-card-shadow;
  border-radius: $mat-card-border-radius;

  .row-show-more {
    .col-show-more {
      text-align: center;
      @include theme.get-theme-color-property(color, primary, 700);
    }
  }

  .table-filter-summary {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .btn-container {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.mat-table {
  width: 100%;

  th.mat-header-cell,
  td.mat-cell {
    padding: 5px;
  }

  .actions-col {
    width: 1px;
  }
}


.list-thumbnail-container {
  margin: 0;
  border-radius: .5rem;
  overflow: hidden;
  display: inline-flex;
  box-shadow: $mat-elevation-z1;

  &:hover {
    outline-style: solid;
    outline-width: 1px;
    @include theme.get-theme-color-property(background, primary, 100);
    @include theme.get-theme-color-property(outline-color, primary);
    cursor: pointer;
  }

  .thumbnail-placeholder {
    width: 75px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .mat-icon {
      @include theme.get-theme-color-property(color, primary);
    }
  }
}

td {
  .mat-chip-list.chip-list-small {
    >.mat-chip-list-wrapper {
      padding: 0;

      .mat-chip {
        font-size: 11px;
        min-height: 24px;
        padding: 4px 10px;
      }
    }
  }
}
