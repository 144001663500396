/* ----- THEMING ----- */
@use "@angular/material" as mat;
@use "themes/light" as light;
@use "themes/typography" as typography;
@use "themes/custom-theme" as custom;
@use "@angular/cdk";


@include mat.core(typography.$polar-typography);

@include mat.core-theme(custom.$custom-theme);
@include mat.all-component-themes(custom.$custom-theme);

/* ----- TAILWIND ----- */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ----- CUSTOM STYLES ----- */
@import "variables", "mixins", "globals";
@import "components/tables", "components/sidenav", "components/forms",
"components/snack", "components/buttons", "components/toolbars",
"components/upload", "components/modals", "components/big-calendar",
"components/accordions", "components/cards", "components/alerts", "components/button-group";



// Scroll bar for chrome START
/* Track */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: rgba(125, 125, 125, 0.7);
    border-radius: 6px;
    border: 3px solid rgba(0, 0, 0, 0);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(125, 125, 125, 0.9);
}

/* Track on hover */
::-webkit-scrollbar-track:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Handle on active */
::-webkit-scrollbar-thumb:active {
    background-color: rgba(125, 125, 125, 1);
}

/* Track on active */
::-webkit-scrollbar-track:active {
    background-color: rgba(255, 255, 255, 0.2);
}

// Scroll bar for chrome END

// right margin and width fix for browser

.m-r-firefox {
    margin-right: 32px !important;
    width: -moz-available !important;
}

.m-r-chrome {
    margin-right: 37px !important;
    width: -webkit-fill-available !important;
}

.new-modal .mat-dialog-container {
  padding: 0;
  .mat-dialog-content {
    padding: 16px;
    margin: 0;
  }
}
.mat-card {
    z-index: 1 !important;
} //do not remove this until mat card are totally removed from the code
