@use 'themes/theme' as theme;

.bc-container {
  min-width: 700px;
  position: relative;
  display: flex;
  flex-direction: column;

  .bc-year-calendar-container,
  .bc-month-calendar-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .bc-row {
    width: 100%;
    display: flex;
    align-items: stretch;
    position: relative;
    flex: 1;

    &.bc-header-row {
      flex: 0;
    }

    &.bc-calendar-header {
      display: flex;
      align-items: center;
      background: $bcBackgroundColor;
      padding: 0.5rem;
      border-bottom: 1px solid $bcBorderColor;

      .dark-theme & {
        background: $bcBackgroundColorDarkTheme;
        border-bottom: 1px solid $bcBorderColorDarkTheme;
      }

      .bc-calendar-legend {
        padding: 0.5rem;
        margin-left: 0.5rem;

        >ul {
          margin-bottom: 0;

          >li {
            font-size: 0.9rem;

            span {
              width: 20px;
              height: 20px;
              float: left;
              position: relative;
              text-align: center;
              margin-right: 0.5rem;
              box-shadow: 0 1px 2px #666;
            }
          }
        }
      }

      .form-control {
        max-width: 4.5rem;
      }
    }

    .bc-absolute-container {
      position: absolute;
      top: 3rem;
      bottom: 0;
      left: $bcWeekNumberWidth;
      right: 0;
      background: rgba(0, 0, 0, 0);
      height: 2.5rem;
    }

    .bc-col,
    .bc-col-header {
      // min-height: $bcColMinHeight;
      min-height: 100%;
      //border: 1px solid $bcBorderColor;
      background: $bcBackgroundColor;

      .dark-theme & {
        background: $bcBackgroundColorDarkTheme;
        border: 1px solid $bcBorderColorDarkTheme;
      }
    }

    .bc-col {
      padding: 0.5rem 0;
    }

    .bc-col-header {
      min-height: $bcColHeaderMinHeight;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8rem;
      border: 1px solid $bcBorderColor;
    }

    .bc-weekday-label {
      width: 4.125rem;
      text-align: center;
      text-transform: uppercase;
      color: $bcWeekdayLabelColor;
      height: 100%;

      .dark-theme & {
        color: $bcWeekdayLabelColorDarkTheme;
      }

      // flex: 1;
    }

    .bc-number {
      text-align: center;
      color: $bcNumberColor;
      line-height: 28px;

      // @include theme.get-theme-color-property(color, primary, '500-contrast');
      .dark-theme & {
        color: $bcNumberColorDarkTheme;
      }
    }

    .bc-week-number {
      // @include theme.get-theme-color-property(background, primary, 100);

      width: $bcWeekNumberWidth;
      background: $bcWeekNumberBackgroundColor;
      border-left: 1px solid $bcBorderColor;
      // border: 1px solid $bcWeekNumberBackgroundColor  !important;
      .dark-theme & {
        background: $bcWeekNumberBackgroundColorDarkTheme;
        border-left: 1px solid $bcBorderColorDarkTheme;
      }
    }

    .bc-col.bc-week-number {
      border-top: none !important;
      border-bottom: 1px solid $bcBorderColor  !important;

      .dark-theme & {
        border-bottom: 1px solid $bcBorderColorDarkTheme !important;
      }
    }

    .bc-day {
      width: 4.125rem;

      &.bc-odd-month-day {
        background-color: $bcOddMonthDayColor;

        .dark-theme & {
          background-color: $bcOddMonthDayColorDarkTheme;
        }
      }

      .bc-day-number {
        position: relative;

        .bc-weekday-deviation {
          position: absolute;
          top: 0.25rem;
          left: 1rem;
          // font-weight: 600;
          font-size: 0.8rem;
          text-transform: uppercase;
        }
      }
    }
  }

  .bc-week-month-label {
    flex: 1;
    font-size: 0.8rem;
    color: $bcMonthLabelColor;
    background-color: $bcMonthBackgroundColor;
    border-right: 1px solid $bcBorderColor;
    // @include theme.get-theme-color-property(background, primary, 50);
    padding: 0.25rem 0.5rem;

    .dark-theme & {
      color: $bcMonthLabelColorDarkTheme;
      background-color: $bcMonthBackgroundColorDarkTheme;
      border-right: 1px solid $bcBorderColorDarkTheme;
    }
  }

  .bc-week-row-height {
    min-height: 5.5rem !important;
  }

  .bc-day-attendance-items-list {
    margin-top: 3rem;
    margin-bottom: 0;
    padding: 0.5rem;

    .bc-day-attendance-item {
      display: flex;
      margin-bottom: 0.25rem;

      .bc-day-attendance-state-marker {
        width: 0.75rem;
        height: 0.75rem;
        margin-top: 0.25rem;
        margin-right: 0.5rem;
      }

      .bc-day-attendance-state-name {
        font-weight: 600;
        font-size: 0.9rem;
      }

      .bc-day-attendance-time {
        font-size: 0.9rem;
      }
    }

    .bc-day-focused {
      @include theme.get-theme-color-property(border-color, primary);
    }
  }
}
