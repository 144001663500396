.mat-dialog-actions {
  justify-content: end;
}

.mat-dialog-close-button {
  float: right;
  top: -20px;
  right: -20px;

  .mat-icon {
    transition: .25s ease-in-out;
  }

  .mat-icon:hover {
    transform: rotate(180deg);
  }
}

.mat-dialog-content {
  max-height: initial !important;
}
