@use "@angular/material" as mat;
@use 'themes/theme' as theme;
@import 'variables';

.c-sidenav {

  padding: 1rem;
  max-width: 300px;

  >.mat-nav-list {
    >.mat-subheader.nav-group-header {
      padding: 4px 16px;
      height: 24px;
      margin-bottom: 8px;

      // .mat-list-icon {
      //   flex-shrink: 0;
      //   width: 24px;
      //   height: 24px;
      //   font-size: 24px;
      //   box-sizing: content-box;
      //   border-radius: 50%;
      //   padding: 4px;
      // }

      .nav-group-header-text {
        text-transform: uppercase;
        // padding-left: 16px;
      }
    }

    .list-item-text {
      min-width: 180px;
    }

    .list-sub-item-text {
      font-size: 15px !important;
    }

    .mat-list-item {
      margin-bottom: .5rem;
      height: 40px !important;

      &.active {
        @include theme.get-theme-color-property(background, primary);
        @include theme.get-theme-color-property(color, primary, '500-contrast');
        box-shadow: $mat-elevation-z3;
        border-radius: $mat-card-border-radius;

        .stacked-icons {
          .mat-icon:last-child {
            @include theme.get-theme-color-property(color, accent, 100);
          }
        }
      }

      .stacked-icons {
        position: relative;

        .mat-icon {
          position: absolute;

        }

        .mat-icon:first-child {
          top: 0;
          left: 0;
        }

        .mat-icon:last-child {
          width: 20px;
          height: 20px;
          font-size: 20px;
          bottom: 0;
          right: 0;
          @include theme.get-theme-color-property(color, accent);
        }
      }
    }

    .mat-divider {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

  }

}

.mat-menu-item {
  margin-bottom: 0.5rem;
  height: 40px !important;
  cursor: pointer;
  outline: none;
  display: block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
  line-height: 40px !important;
  font-size: 15px;
  position: relative;
  font-family: "Inter", "Helvetica Neue", sans-serif;
  width: 200px;

  &.active {
    @include theme.get-theme-color-property(background, primary);
    @include theme.get-theme-color-property(color, primary, '500-contrast');
    box-shadow: $mat-elevation-z3;
    border-radius: $mat-card-border-radius;

    .stacked-icons {
      .mat-icon:last-child {
        @include theme.get-theme-color-property(color, accent, 100);
      }
    }
  }


}
